<script setup lang="ts">
import type { Json, Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';
import { createOptionSchema } from '~/util/validation';

const { details } = defineProps({
  details: {
    type: Object,
    required: true,
  },
});

const integrationStore = useIntegrationStore();
const emit = defineEmits(['confirm']);
const modal = useModal();
const form = ref();
const errorMessage = ref<string | null>(null);

onMounted(async () => {
  try {
    await form.value?.validate();
  } catch (error) {
    // Intentionally empty to prevent throwing errors on validation
  }
});

const preAuthForm = reactiveComputed(() => {
  const form = {};
  if (!details.options?.length) return form;
  details.options.forEach((option) => {
    form[option.key] = option.value;
  });
  return form;
});

async function handlePreAuth(event: FormSubmitEvent) {
  try {
    await integrationStore.connect(
      details.key,
      { ...event.data },
      details.metadataOverride ?? {},
    );
    emit('confirm');
    modal.close();
  } catch (err: any) {
    // There was an error creating the integration so we should show the user this error
    errorMessage.value = 'Invalid credentials';
  }
}

const optionSchema = computed(() => {
  const preAuthObj = Object.fromEntries(
    details.options.map((option: Variable) => [option.key, option]),
  ) as Json;
  return createOptionSchema(preAuthObj);
});
</script>
<template>
  <UModal>
    <UCard :ui="{ base: 'w-full' }">
      <UForm
        ref="form"
        :state="preAuthForm"
        :schema="optionSchema"
        @submit="handlePreAuth"
      >
        <div class="flex flex-row items-center justify-start w-full gap-2">
          <UIcon :name="details.icon" class="text-3xl" />
          <p class="heading-sm font-bold">Connect {{ details.name }}</p>
        </div>
        <!-- Display error message -->
        <div
          v-if="errorMessage"
          class="bg-red-100 border border-red-500 text-red-500 p-4 rounded mt-2"
        >
          {{ errorMessage }}
        </div>
        <p class="caption dimmed">{{ details.preAuthCaption }}</p>

        <AppInput
          v-for="option in details.options"
          :key="option.key"
          v-model="preAuthForm[option.key]"
          :option="option"
          class="mt-m"
          local-only
        />

        <UButton
          type="submit"
          :disabled="form?.errors?.length"
          label="Create Connection"
          size="xl"
          class="mt-l"
        />
      </UForm>
    </UCard>
  </UModal>
</template>
