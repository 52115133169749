<script setup lang="ts">
import type { IntegrationLink } from '@respell/database';
import type { Json, Variable } from '@respell/utils';
import ContextInput from '~/components/editor/config/ContextInput.vue';
import { createOptionSchema } from '~/util/validation';

const { details, availableAccount, linkedAccount } = defineProps<{
  details: Json;
  availableAccount: IntegrationLink;
  linkedAccount?: IntegrationLink;
}>();

const integrationStore = useIntegrationStore();
const emit = defineEmits(['confirm']);
const modal = useModal();
const form = ref();
const submitted = ref(false);

onMounted(async () => {
  setTimeout(async () => {
    try {
      await form.value?.validate();
    } catch (error) {
      // Intentionally empty to prevent throwing errors on validation
    }
  }, 1500);

  window.addEventListener('beforeunload', handleBeforeUnload);
});

const postAuthForm = reactiveComputed(() => {
  const form = {};
  if (!details?.postAuthOptions?.length) return form;
  details.postAuthOptions.forEach((option) => {
    form[option.key] = option.value;
  });
  return form;
});

async function handlePostAuth(event: FormSubmitEvent) {
  if (!availableAccount && !linkedAccount) return;
  await integrationStore.savePostAuthData(
    linkedAccount?.id ?? availableAccount.id,
    {
      ...event.data,
    },
  );
  emit('confirm');
  modal.close();
}

const optionSchema = computed(() => {
  const postAuthObj = Object.fromEntries(
    details.postAuthOptions.map((option: Variable) => [option.key, option]),
  ) as Json;
  return createOptionSchema(postAuthObj);
});

// Disconnect the integration if post auth not submitted
async function handleBeforeUnload(event: BeforeUnloadEvent) {
  if (!submitted.value) {
    await integrationStore.disconnect(availableAccount.id);
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
<template>
  <UModal prevent-close>
    <UCard :ui="{ base: 'w-full' }">
      <UForm
        v-if="details"
        ref="form"
        :state="postAuthForm"
        :schema="optionSchema"
        @submit="handlePostAuth"
      >
        <div class="flex flex-row items-center justify-start w-full gap-2">
          <UIcon :name="details.icon" class="text-3xl" />
          <p class="heading-sm font-bold">
            Finish setting up {{ details.name }}
          </p>
        </div>
        <p class="caption dimmed">{{ details.postAuthCaption }}</p>

        <ContextInput
          v-for="option in details.postAuthOptions"
          :key="option.key"
          v-model="postAuthForm[option.key]"
          :option="option"
          :linked-account="linkedAccount ?? availableAccount"
          class="mt-m"
          local-only
        />

        <UButton
          :disabled="form?.errors?.length"
          type="submit"
          label="Finish setup"
          size="xl"
          class="mt-l"
        />
      </UForm>
    </UCard>
  </UModal>
</template>
